<template>
  <v-dialog v-model="dialog" max-width="1300px" no-click-animation scrollable fullscreen>
    <v-card>
      <v-card-title
        ><v-btn icon @click="close" class="mr-2"> <v-icon>mdi-close</v-icon> </v-btn>Find Available
        Vehicles</v-card-title
      >
      <v-card-text class="pb-0">
        <v-row class="ma-0">
          <v-spacer></v-spacer>
          <v-col cols="12" md="6">
            <v-card outlined>
              <v-form ref="dateFilterform" v-model="isDateFilterFormValid">
                <v-row>
                  <v-col cols="12" md="6" class="pb-0">
                    <v-card-title>Leave Date</v-card-title>
                    <v-card-text>
                      <date-picker
                        ref="leaveDate"
                        label="Date"
                        v-model="leaveDate"
                        :rules="[() => !!leaveDate || 'This field is required']"
                        required
                      ></date-picker>
                      <time-picker
                        ref="leaveTime"
                        label="Time"
                        v-model="leaveTime"
                        :rules="[() => !!leaveTime || 'This field is required']"
                        required
                      ></time-picker>
                    </v-card-text>
                  </v-col>
                  <v-col cols="12" md="6" class="pb-0">
                    <v-card-title>Return Date</v-card-title>
                    <v-card-text>
                      <date-picker
                        ref="returnDate"
                        label="Date"
                        v-model="returnDate"
                        :rules="[() => !!returnDate || 'This field is required']"
                        required
                      ></date-picker>
                      <time-picker
                        ref="returnTime"
                        label="Time"
                        v-model="returnTime"
                        :rules="[() => !!returnTime || 'This field is required']"
                        required
                      ></time-picker>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-form>
              <div class="d-flex ma-2 justify-end">
                <div class="mr-2">
                  <v-btn @click="getItems" color="primary"> Find Vehicles </v-btn>
                </div>
                <div>
                  <v-btn @click="clearFilters" text> Clear Filters </v-btn>
                </div>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-row dense>
              <v-col cols="12" md="12">
                <v-select
                  v-model="filterVehicleType"
                  :items="vehicleTypes"
                  item-text="name"
                  item-value="id"
                  label="Vehicle Type"
                  outlined
                  clearable
                  @change="filterList()"
                ></v-select>
              </v-col>
              <v-col cols="12" md="12">
                <v-select
                  v-model="filterVehicleLocation"
                  :items="vehicleLocations"
                  item-text="name"
                  item-value="id"
                  label="Vehicle Location"
                  outlined
                  clearable
                  @change="filterList()"
                ></v-select>
              </v-col>
              <v-col cols="12" md="12">
                <v-checkbox class="mt-0" v-model="showWithLift" @change="filterList()" label="w/ Wheelchair Lift">
                </v-checkbox>
              </v-col>
            </v-row>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row v-for="am in activeMessages" :key="am.id" dense class="d-flex justify-center">
          <v-col cols="10">
            <v-alert outlined :type="am.alertType || 'info'" text>{{ am.message }}</v-alert>
          </v-col>
        </v-row>
        <v-row dense class="d-flex justify-center">
          <!-- <v-col cols="2">
            <date-picker
              v-model="detailsDate"
              :min="leaveDate"
              :max="returnDate"
              :readonly="!leaveDate && !returnDate"
            ></date-picker>
          </v-col> -->
          <v-col cols="6">
            <div>
              <div class="d-flex justify-space-between flex-row">
                <div>Leave Date: {{ readableDate(filterCopy.leaveDate) }} {{ readableTime(filterCopy.leaveTime) }}</div>
                <div>
                  Return Date: {{ readableDate(filterCopy.returnDate) }} {{ readableTime(filterCopy.returnTime) }}
                </div>
              </div>
            </div>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left"># Vehicles Unassigned</th>
                    <th class="text-left">Approved</th>
                    <th class="text-left">Pending Approval</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ unassigned }}</td>
                    <td>{{ approved }}</td>
                    <td>{{ pending }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col cols="12" md="12">
            <v-data-table
              fixed-header
              :headers="headers"
              :items="filteredAvailable"
              item-key="id"
              no-data-text="No vehicles available"
              :height="tableHeight"
            >
              <template v-slot:[`item.type`]="{ item }">
                {{ vehicleTypesById[item.type] ? vehicleTypesById[item.type].name : '-' }}
              </template>
              <template v-slot:[`item.driver`]="{ item }">
                {{
                  vehicleTypesById[item.driverId]
                    ? driversById[item.driverId].firstName + ' ' + driversById[item.driverId].lastName
                    : item.driver || '-'
                }}
              </template>
              <template v-slot:[`item.cap`]="{ item }">
                {{ item.capacityElem }}/{{ item.capacityMid }}/{{ item.capacityHigh }}
              </template>
              <template v-slot:[`item.location`]="{ item }">
                {{ getVehicleLocation(item.id) }}
              </template>
              <template v-slot:[`item.lift`]="{ item }">
                <v-icon>mdi-{{ item.wheelchairLift ? 'check' : 'close' }}</v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn class="mx-2" text @click="close">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { format } from 'date-fns';
import { GET_AVAILABLE_VEHICLES } from '@/store/modules/Vehicle/actions';
import { GET_ASSIGNMENTS } from '@/store/modules/Assignment/actions';
import DatePicker from '@/components/DatePicker';
import TimePicker from '@/components/TimePicker';
import { getVehicleLocation, readableDate, readableTime } from '@/util';
import { isEqual } from 'lodash';

export default {
  name: 'ViewAvailableVehicles',
  inject: ['eventHub'],
  mixins: [],
  components: { DatePicker, TimePicker },
  props: {},
  data() {
    return {
      format,
      getVehicleLocation,
      readableDate,
      readableTime,
      dialog: false,
      available: [],
      filteredAvailable: [],
      leaveDate: null,
      leaveTime: null,
      returnDate: null,
      returnTime: null,
      filterCopy: {
        leaveDate: null,
        leaveTime: null,
        returnDate: null,
        returnTime: null,
      },
      isDateFilterFormValid: true,
      filterVehicleType: 0,
      filterVehicleLocation: 0,
      showWithLift: false,
      activeMessages: [],
      // detailsDate: null,
      assignments: [],
      unassigned: 0,
      approved: 0,
      pending: 0,
      headers: [
        { text: 'Type', value: 'type', sortable: false },
        { text: 'Vehicle', value: 'name', sortable: false },
        { text: 'Driver', value: 'driver', sortable: false },
        { text: 'Capacity', value: 'cap', sortable: false },
        { text: 'Location', value: 'location', sortable: false },
        { text: 'Travel Conditions', value: 'conditions', sortable: false },
        { text: 'Lift', value: 'lift', sortable: false },
        { text: 'WC Slots', value: 'wheelchair', sortable: false },
        { text: 'Harnesses', value: 'numSafetyVest', sortable: false },
        { text: 'Fold Down Seats', value: 'numFoldDownSeat', sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters('driver', ['drivers', 'driversById']),
    ...mapGetters('location', ['locations', 'locationsById', 'vehicleLocations']),
    ...mapGetters('destination', ['destinations', 'destinationsById']),
    ...mapGetters('driver', ['driversById']),
    ...mapGetters('vehicle', ['vehicles', 'vehiclesById']),
    ...mapGetters('vehicleType', ['vehicleTypes', 'vehicleTypesById']),
    ...mapGetters('stop', ['stopsById']),
    ...mapGetters('config', ['vehicleOwnerConfig']),
    tableHeight() {
      return window.innerHeight - 540;
    },
  },
  methods: {
    ...mapActions('vehicle', [GET_AVAILABLE_VEHICLES]),
    ...mapActions('assignment', [GET_ASSIGNMENTS]),
    async getItems() {
      if (
        isEqual(this.filterCopy, {
          leaveDate: this.leaveDate,
          leaveTime: this.leaveTime,
          returnDate: this.returnDate,
          returnTime: this.returnTime,
        })
      ) {
        // if the filters are the same as the last time, don't make the call
        if (
          !this.leaveDate &&
          !this.leaveTime &&
          !this.returnDate &&
          !this.returnTime &&
          this.activeMessages.length == 0
        )
          this.activeMessages.push({
            message: 'Please select leave and return dates and times',
            alertType: 'error',
          });
        return;
      }
      if (this.leaveDate && this.leaveTime && this.returnDate && this.returnTime && this.validate()) {
        this.filterCopy.leaveDate = this.leaveDate;
        this.filterCopy.leaveTime = this.leaveTime;
        this.filterCopy.returnDate = this.returnDate;
        this.filterCopy.returnTime = this.returnTime;

        this.available = await this.getAvailableVehicles({
          fromDate: this.leaveDate,
          fromTime: this.leaveTime,
          toDate: this.returnDate,
          toTime: this.returnTime,
        });
        this.assignments = await this.getAssignments({
          fromDate: this.leaveDate,
          fromTime: this.leaveTime,
          toDate: this.returnDate,
          toTime: this.returnTime,
        });
        this.assignments = this.assignments.filter((e) => e.vehicleId);
        this.filterList();
        this.datesChanged = false;
      } else {
        this.available = [];
        if (this.activeMessages.length == 0)
          this.activeMessages.push({
            message: 'Please select leave and return dates and times',
            alertType: 'error',
          });
      }
    },
    filterList() {
      this.filteredAssignments = this.assignments;
      this.filteredAvailable = this.available.filter((e) => !this.filteredAssignments.find((a) => a.vehicleId == e.id));
      if (this.filterVehicleType) {
        this.filteredAvailable = this.filteredAvailable.filter((e) => e.type == this.filterVehicleType);
        this.filteredAssignments = this.filteredAssignments.filter(
          (e) => this.vehiclesById[e.vehicleId].type == this.filterVehicleType
        );
      }
      if (this.filterVehicleLocation) {
        this.filteredAvailable = this.filteredAvailable.filter(
          (e) => e.depotId == this.locationsById[this.filterVehicleLocation].depotId
        );
        this.filteredAssignments = this.filteredAssignments.filter(
          (e) => this.vehiclesById[e.vehicleId].depotId == this.locationsById[this.filterVehicleLocation].depotId
        );
      }
      if (this.showWithLift) {
        this.filteredAvailable = this.filteredAvailable.filter((e) => e.wheelchairLift);
        this.filteredAssignments = this.filteredAssignments.filter(
          (e) => this.vehiclesById[e.vehicleId].wheelchairLift
        );
      }
      this.updateTotals();
    },
    updateTotals() {
      this.unassigned = this.filteredAvailable.length;
      this.approved = this.filteredAssignments.filter((e) => e.status == 1).length;
      this.pending = this.filteredAssignments.filter((e) => e.status == 0).length;
    },
    async getAssignmentDetails() {
      this.approved = this.assignments.filter((e) => e.status == 1).length;
      this.pending = this.assignments.filter((e) => e.status == 0).length;
    },
    close() {
      this.dialog = false;
    },
    validate() {
      this.activeMessages = [];
      if (this.leaveDate && this.returnDate) {
        if (this.returnDate < this.leaveDate) {
          this.activeMessages.push({
            message: 'Return date cannot be before leave date',
            alertType: 'error',
          });
          return false;
        } else if (
          this.returnDate == this.leaveDate &&
          this.returnTime &&
          this.leaveTime.localeCompare(this.returnTime, undefined, {
            ignorePunctuation: true,
            numeric: true,
          }) > 0
        ) {
          this.activeMessages.push({
            message: 'Return time cannot be before leave time',
            alertType: 'error',
          });
          return false;
        }
      }
      return true;
    },
    clearFilters() {
      this.leaveDate = null;
      this.leaveTime = null;
      this.returnDate = null;
      this.returnTime = null;
      this.$refs.dateFilterform.resetValidation();
      this.activeMessages = [];
    },
  },
  watch: {
    leaveDate(value) {
      if (value && !this.returnDate) this.returnDate = value;
    },
    detailsDate(value) {
      if (value) this.getAssignmentDetails();
    },
  },
};
</script>

<style lang="scss">
.v-input__icon--append .v-icon {
  color: #4caf50;
}
</style>
