<template>
  <v-badge
    :value="!!tripRequest.attention"
    color="#ff5252"
    icon="mdi-exclamation-thick"
    left
    overlap
    bordered
    class="full-width"
  >
    <v-card class="ma-0">
      <v-container fluid class="pa-0" :class="`${this.status.color.split(' ')[0]}-border `">
        <v-row
          class="ma-0"
          :class="
            this.expand
              ? `${this.status.color} white--text ${this.status.color.split(' ')[0]}-border-bottom`
              : 'black--text'
          "
        >
          <v-col
            cols="auto"
            class="text-left clickable ma-0 no-space d-flex flex-column justify-center"
            :class="numClass"
            @click="openTripRequest"
          >
            <div v-if="tripRequest.batchId" class="text-subtitle-1 text-center">Batch #{{ tripRequest.batchId }}</div>
            <div v-if="tripRequest.localId" class="text-subtitle-1 text-center">{{ tripRequest.localId }}</div>
            <div class="text-h5 text-center font-weight-bold">#{{ tripRequest.id }}</div>
            <div class="icon-wrapper">
              <v-tooltip bottom v-if="tripRequest.recurrence && tripRequest.recurrence.length">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    :class="
                      expand
                        ? `${status.color.split(' ')[0]}--text text--${status.color.split(' ')[1]} mx-1`
                        : `white--text mx-1`
                    "
                  >
                    mdi-autorenew
                  </v-icon>
                </template>
                <span>Recurring Trip</span>
              </v-tooltip>
              <v-tooltip bottom v-if="tripRequest.dropoffOnly || tripRequest.pickupOnly"
                ><template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    :class="
                      expand
                        ? `${status.color.split(' ')[0]}--text text--${status.color.split(' ')[1]} mx-1`
                        : `white--text mx-1`
                    "
                  >
                    mdi-map-marker-alert-outline
                  </v-icon></template
                ><span>{{ tripRequest.pickupOnly ? 'Pickup' : 'Dropoff' }} only trip</span></v-tooltip
              >
              <v-tooltip bottom v-if="tripRequest.splitTrip">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    :class="
                      expand
                        ? `${status.color.split(' ')[0]}--text text--${status.color.split(' ')[1]} mx-1`
                        : `white--text mx-1`
                    "
                  >
                    mdi-swap-horizontal
                  </v-icon>
                </template>
                <span>Split trip</span>
              </v-tooltip>
              <v-tooltip bottom v-if="wasRescheduled.show">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    :class="
                      expand
                        ? `${status.color.split(' ')[0]}--text text--${status.color.split(' ')[1]} mx-1`
                        : `white--text mx-1`
                    "
                  >
                    mdi-calendar
                  </v-icon>
                </template>
                <span>{{ wasRescheduled.message }}</span>
              </v-tooltip>
              <v-tooltip bottom v-if="tripRequest.awayForLunch && tripRequest.needLunch">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    :class="
                      expand
                        ? `${status.color.split(' ')[0]}--text text--${status.color.split(' ')[1]} mx-1`
                        : `white--text mx-1`
                    "
                  >
                    mdi-food-apple
                  </v-icon>
                </template>
                <span>Students need lunches</span>
              </v-tooltip>
              <v-tooltip bottom v-if="tripRequest.haveHealthConcerns">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    :class="
                      expand
                        ? `${status.color.split(' ')[0]}--text text--${status.color.split(' ')[1]} mx-1`
                        : `white--text mx-1`
                    "
                  >
                    mdi-medical-bag
                  </v-icon>
                </template>
                <span>Health concerns</span>
              </v-tooltip>

              <v-tooltip bottom v-if="tripRequest.outOfState">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    :class="
                      expand
                        ? `${status.color.split(' ')[0]}--text text--${status.color.split(' ')[1]} mx-1`
                        : `white--text mx-1`
                    "
                  >
                    mdi-weather-night
                  </v-icon>
                </template>
                <span>Overnight trip</span>
              </v-tooltip>
              <v-tooltip bottom v-if="tripRequest.needExternalTransportation">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    :class="
                      expand
                        ? `${status.color.split(' ')[0]}--text text--${status.color.split(' ')[1]} mx-1`
                        : `white--text mx-1`
                    "
                  >
                    mdi-train-car
                  </v-icon>
                </template>
                <span>Needs external transportation</span>
              </v-tooltip>
              <v-tooltip bottom v-if="tripRequest.pendingDrivers">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    :class="
                      expand
                        ? `${status.color.split(' ')[0]}--text text--${status.color.split(' ')[1]} mx-1`
                        : `white--text mx-1`
                    "
                  >
                    mdi-account-alert
                  </v-icon>
                </template>
                <span>Trip is pending driver(s)</span>
              </v-tooltip>
              <v-tooltip bottom v-if="tripRequest.pendingVehicles">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    :class="
                      expand
                        ? `${status.color.split(' ')[0]}--text text--${status.color.split(' ')[1]} mx-1`
                        : `white--text mx-1`
                    "
                  >
                    mdi-bus-alert
                  </v-icon>
                </template>
                <span>Trip is pending vehicle(s)</span>
              </v-tooltip>
              <v-tooltip bottom v-if="tripRequest.hasAttachment">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    :class="
                      expand
                        ? `${status.color.split(' ')[0]}--text text--${status.color.split(' ')[1]} mx-1`
                        : `white--text mx-1`
                    "
                  >
                    mdi-paperclip
                  </v-icon>
                </template>
                <span>Trip has attachment(s)</span>
              </v-tooltip>
              <v-tooltip bottom v-if="tripRequest.actualTimeTBD">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    :class="
                      expand
                        ? `${status.color.split(' ')[0]}--text text--${status.color.split(' ')[1]} mx-1`
                        : `white--text mx-1`
                    "
                  >
                  mdi-clock-alert-outline
                  </v-icon>
                </template>
                <span>Actual Time TBD</span>
              </v-tooltip>
            </div>
          </v-col>
          <template v-if="expand">
            <v-col cols="12" md="2" class="pl-4 no-space">
              <div class="text-overline font-weight-bold">Requester</div>
              <div class="text-subtitle-1">
                {{
                  tripRequest.submittedUser && usersById[tripRequest.submittedUser]
                    ? usersById[tripRequest.submittedUser].displayName
                    : 'Legacy Requester'
                }}
              </div>
            </v-col>
            <v-col cols="12" md="2" class="no-space">
              <div class="text-overline font-weight-bold">Submitted</div>
              <div class="text-subtitle-1">
                {{
                  tripRequest.submittedTimestamp ? getDisplayDate(fromUnixTime(tripRequest.submittedTimestamp)) : '-'
                }}
              </div>
            </v-col>
            <v-col cols="12" md="2" class="no-space">
              <div class="text-overline font-weight-bold"># Adults / Students</div>
              <div class="text-subtitle-1">{{ tripRequest.totalAdults }} / {{ tripRequest.totalStudents }}</div>
            </v-col>
            <v-col cols="12" md="2" class="no-space">
              <div class="text-overline font-weight-bold">Grade(s)</div>
              <div class="text-subtitle-1">{{ grades }}</div>
            </v-col>
          </template>
          <template v-else>
            <v-col cols="12" md="2" class="ml-2 no-space">
              <div class="text-overline font-weight-bold">Leave / Return</div>
              <div class="text-subtitle-1">{{ leaveDate }} @ {{ leaveTime }}</div>
              <div class="text-subtitle-1">{{ returnDate }} @ {{ returnTime }}</div>
            </v-col>
            <v-col cols="12" md="2" class="no-space">
              <div class="text-overline font-weight-bold">Location</div>
              <div class="text-subtitle-1">{{ locationName }}</div>
            </v-col>
            <v-col cols="12" md="2" class="no-space">
              <div class="text-overline font-weight-bold">Destination</div>
              <div class="text-subtitle-1">{{ destinationName }}</div>
            </v-col>
            <v-col cols="12" md="2" class="no-space">
              <div class="text-overline font-weight-bold">Trip Type / Event(s)</div>
              <div class="text-subtitle-1">{{ tripType }} / {{ tripEvents }}</div>
            </v-col>
          </template>
          <v-col cols="12" md="2" class="no-space">
            <div class="text-overline font-weight-bold">Status</div>
            <v-tooltip bottom :disabled="!status.tooltip">
              <template v-slot:activator="{ on, attrs }">
                <div class="text-subtitle-1" v-bind="attrs" v-on="on">
                  <v-icon v-if="tripRequest.approval.approved" :color="expand ? 'white' : 'success'">
                    mdi-check-circle
                  </v-icon>
                  {{ status.display }}
                </div>
              </template>
              <span v-html="status.tooltip"></span>
            </v-tooltip>
            <div class="text-subtitle-1">
              <v-icon
                v-if="!tripRequest.numVehicles || (tripRequest.canAssign && tripRequest.assignmentsComplete)"
                :color="expand ? 'white' : 'success'"
              >
                mdi-check-circle
              </v-icon>
              {{ assignmentStatus }}
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto px-0">
            <v-btn icon @click="handleExpand">
              <v-icon :class="expand ? 'white--text' : ''">{{ expand ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
          </v-col>
          <v-col
            cols="auto px-0"
            v-if="!hideQuickActions"
            v-show="me.is.superAdmin || me.is.transportationAdmin || tripRequest.approval.history.length"
          >
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon :class="expand ? 'white--text' : ''">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-show="tripRequest.auditHistory.length" @click="showHistory()">
                  <v-list-item-icon><v-icon color="primary">mdi-history</v-icon></v-list-item-icon>
                  <v-list-item-title>Audit History</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="tripRequest.status == 1"
                  v-show="me.is.superAdmin || me.is.transportationAdmin"
                  @click="reschedule()"
                >
                  <v-list-item-icon><v-icon color="primary">mdi-calendar-arrow-right</v-icon></v-list-item-icon>
                  <v-list-item-title>Reschedule</v-list-item-title>
                </v-list-item>
                <v-list-item v-show="canCreateDuplicate" @click="createDuplicate()">
                  <v-list-item-icon><v-icon color="primary">mdi-content-copy</v-icon></v-list-item-icon>
                  <v-list-item-title>Duplicate</v-list-item-title>
                </v-list-item>
                <v-list-item v-show="me.is.superAdmin || me.is.transportationAdmin" @click="showChangeSubmitter()">
                  <v-list-item-icon><v-icon color="primary">mdi-account-convert</v-icon></v-list-item-icon>
                  <v-list-item-title>Change Submitter</v-list-item-title>
                </v-list-item>
                <v-list-item v-show="showDownloadPermissionSlip" @click="permissionSlip()">
                  <v-list-item-icon><v-icon color="primary">mdi-download</v-icon></v-list-item-icon>
                  <v-list-item-title>Permission Slip</v-list-item-title>
                </v-list-item>
                <v-list-item v-show="showCancelButton" @click="cancel()">
                  <v-list-item-icon><v-icon color="primary">mdi-cancel</v-icon></v-list-item-icon>
                  <v-list-item-title>Cancel</v-list-item-title>
                </v-list-item>
                <v-list-item v-show="showReinstanteButton" @click="reinstate()">
                  <v-list-item-icon><v-icon color="primary">mdi-restart</v-icon></v-list-item-icon>
                  <v-list-item-title>Reinstate</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
        <v-divider v-if="expand"></v-divider>
        <v-expand-transition>
          <v-row class="ma-0" v-show="expand">
            <v-col cols="12" md="4">
              <v-row class="px-2">
                <v-col cols="12" md="4">
                  <div class="text-overline">Leave</div>
                  <div class="text-h5 font-weight-bold">{{ leaveDate }}</div>
                  <div class="text-subtitle-1">{{ leaveTime }}</div>
                  <div class="text-overline">Return</div>
                  <div class="text-h5 font-weight-bold">{{ returnDate }}</div>
                  <div class="text-subtitle-1">{{ returnTime }}</div>
                </v-col>
                <v-col cols="12" md="8" class="no-space">
                  <div class="text-overline">Route</div>
                  <v-list flat class="py-0">
                    <v-list-item v-for="(stop, i) of route" :key="i" class="pl-0 py-0">
                      <v-list-item-icon>
                        <v-icon>{{ stop.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ stop.name }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="12" md="2" class="pl-4 no-space">
              <div class="text-overline font-weight-bold">Trip Type</div>
              <div class="text-subtitle-1">{{ tripType }}</div>
              <div class="text-overline font-weight-bold">Trip Event(s)</div>
              <div class="text-subtitle-1">{{ tripEvents }}</div>
              <div class="text-overline font-weight-bold">Owner</div>
              <v-tooltip bottom :disabled="!vehicleOwner.email">
                <template v-slot:activator="{ on, attrs }">
                  <div class="text-subtitle-1" v-bind="attrs" v-on="on">
                    {{ vehicleOwner.display }}
                  </div>
                </template>
                <span v-html="vehicleOwner.email"></span>
              </v-tooltip>
              <div class="text-overline font-weight-bold">Funding Source(s)</div>
              <div class="text-subtitle-1">{{ fundingSources }}</div>
              <!-- <div class="text-overline font-weight-bold" v-if="showApproverInfo">
                <div>Funding Approver</div>
                <div class="text-subtitle-1 text-capitalize">
                  {{ showApproverInfo }}
                </div>
              </div> -->
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="12" md="2" class="pl-4 no-space">
              <div class="text-overline font-weight-bold">Wheelchair Lift</div>
              <div class="text-subtitle-1">{{ tripRequest.needSpecialNeedsVehicle ? 'Yes' : 'No' }}</div>
              <div class="text-overline font-weight-bold">Special Equipment</div>
              <div class="text-subtitle-1">{{ specEq }}</div>
              <div class="text-overline font-weight-bold">Special Indicators</div>
              <div class="text-subtitle-1">{{ specIns }}</div>
              <div class="text-overline font-weight-bold amber--text text--darken-2" v-if="tripRequest.needSubstitute">
                Substitute Needed
              </div>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="12" md="2" class="pl-4 no-space">
              <div class="text-overline font-weight-bold"># of Vehicles</div>
              <div class="text-subtitle-1">{{ vehicles }}</div>
              <div class="text-overline font-weight-bold">Bid ID</div>
              <div class="text-subtitle-1">-</div>
              <div class="text-overline font-weight-bold">Zone</div>
              <div class="text-subtitle-1">{{ zone }}</div>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="12" md="2" class="pl-4 no-space">
              <div class="text-overline font-weight-bold">Assignments</div>
              <template v-if="tripRequest.assignments && tripRequest.assignments.length">
                <template v-for="(assignment, i) of tripRequest.assignments">
                  <v-list v-if="i < 2" flat dense class="py-0" :key="assignment.id">
                    <v-list-item class="pl-0 py-0" v-if="assignment.vehicleId || assignment.vehicle">
                      <v-list-item-icon>
                        <v-icon>mdi-bus</v-icon>
                        <v-icon v-if="assignment.leg == 1" color="green">mdi-arrow-right-bold-circle</v-icon>
                        <v-icon v-if="assignment.leg == 2" color="blue">mdi-arrow-left-bold-circle</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content class="py-0 my-0">
                        <v-tooltip bottom :disabled="!getVehicleInfo(assignment).tooltip">
                          <template v-slot:activator="{ on, attrs }">
                            <v-list-item-title v-bind="attrs" v-on="on">
                              {{ getVehicleInfo(assignment).display }}
                              <small class="d-block"> {{ getVehicleLocation(assignment.vehicleId) }} </small>
                            </v-list-item-title>
                          </template>
                          <span v-html="getVehicleInfo(assignment).tooltip"></span>
                        </v-tooltip>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item class="pl-0 py-0" v-if="assignment.driverId || assignment.driver">
                      <v-list-item-icon>
                        <v-icon>mdi-account</v-icon>
                        <v-icon v-if="assignment.leg == 1" color="green">mdi-arrow-right-bold-circle</v-icon>
                        <v-icon v-if="assignment.leg == 2" color="blue">mdi-arrow-left-bold-circle</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content class="py-0 my-0">
                        <v-list-item-title>{{ getDriverInfo(assignment) }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <v-divider :key="assignment.id + 100" v-if="i + 1 < tripRequest.assignments.length"></v-divider>
                </template>
                <p v-if="tripRequest.assignments.length > 2" class="my-2 font-weight-medium">
                  +{{ tripRequest.assignments.length - 2 }} more
                </p>
              </template>
              <div v-else-if="!tripRequest.permissions.canAssign" class="text-subtitle-1">Cannot assign yet</div>
              <div v-else-if="tripRequest.numVehicles" class="text-subtitle-1">No assignments yet</div>
              <div v-else class="text-subtitle-1">No assignments needed</div>
            </v-col>
          </v-row>
        </v-expand-transition>
      </v-container>
    </v-card>
  </v-badge>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { format, fromUnixTime } from 'date-fns';
import { DUPLICATE_TRIP_REQUEST, DOWNLOAD_PERMISSION_SLIP, REINSTATE_TRIP } from '@/store/modules/TripRequest/actions';
import { SAVE_PREFERENCE } from '@/store/modules/User/actions';
import { randomString, getVehicleLocation, getVehicleOwner } from '@/util';
import VEHICLE_TYPES from '@/shared/types';

export default {
  name: 'TripRequest',
  inject: ['eventHub'],
  components: {},
  props: {
    tripRequest: Object,
    allExpanded: Boolean,
    hideQuickActions: Boolean,
    tab: String,
    returnToDashboard: Boolean,
  },
  data() {
    return {
      format,
      fromUnixTime,
      getVehicleLocation,
      VEHICLE_TYPES,
      expand: false,
      color: 'primary',
    };
  },
  computed: {
    ...mapGetters('app', ['hasPermissionSlipEnglishTemplate', 'hasPermissionSlipSpanishTemplate', 'currentSemester']),
    ...mapGetters('user', ['me', 'usersById', 'usersByEmail', 'vehicleOwners']),
    ...mapGetters('fundingSource', ['fundingSources', 'fundingSourcesById']),
    ...mapGetters('location', ['locationsById', 'locations']),
    ...mapGetters('destination', ['destinations', 'allDestinations']),
    ...mapGetters('tripType', ['tripTypesById']),
    ...mapGetters('tripEvent', ['tripEventsById']),
    ...mapGetters('vehicle', ['vehiclesById']),
    ...mapGetters('driver', ['driversById']),
    ...mapGetters('vehicleType', ['vehicleTypesById']),
    ...mapGetters('additionalTransportation', ['additionalTransportationsById']),
    ...mapGetters('config', ['tripRequestConfig', 'specialIndicators']),
    assignmentStatus() {
      if (!this.tripRequest.numVehicles) {
        return 'No Assignments Needed';
      } else if (this.tripRequest.canAssign && this.tripRequest.assignmentsComplete) {
        return 'Assigned';
      } else if (this.tripRequest.assignments && this.tripRequest.assignments.length) {
        return 'Partially Assigned';
      } else {
        return 'No Assignments Made';
      }
    },
    numClass() {
      return this.expand
        ? `white ${this.status.color.split(' ')[0]}--text text--${this.status.color.split(' ')[1]}`
        : `${this.status.color} white--text`;
    },
    grades() {
      if (
        this.tripRequest.gradeLevels &&
        this.tripRequest.gradeLevels.length &&
        this.tripRequest.gradeLevels[0] == 'All'
      )
        return 'All';
      else if (this.tripRequest.gradeLevels && this.tripRequest.gradeLevels.length)
        return this.tripRequest.gradeLevels.join(', ');
      else return 'N/A';
    },
    status() {
      if (this.tripRequest.status == 2) return { display: 'Completed', color: 'green darken-1' };
      else if (this.tripRequest.approval.approved) {
        if (this.tripRequest.canAssign && (this.tripRequest.pendingDrivers || this.tripRequest.pendingVehicles))
          return { display: 'Approved', color: 'blue darken-2' };
        else return { display: 'Approved', color: 'green darken-1' };
      } else if (this.tripRequest.approval.awaitingApproval) {
        let tooltip = this.tripRequest.approval.awaitingApproval.primaryApprovers
          ? this.tripRequest.approval.awaitingApproval.primaryApprovers.map(
              (e) => `${e.userDisplayName} (${e.userEmail})`
            )
          : [];
        tooltip = tooltip.join('<br>');
        return {
          display: 'Pending - ' + this.tripRequest.approval.awaitingApproval.name,
          tooltip,
          color: 'orange darken-3',
        };
      } else if (this.tripRequest.status == -2) return { display: 'Denied', color: 'red accent-2' };
      else if (this.tripRequest.status == -3) return { display: 'Canceled', color: 'red accent-2' };
      else if (
        (this.tripRequest.vehicleType == this.VEHICLE_TYPES.APPROVED_CHARTER ||
          this.tripRequest.vehicleType == VEHICLE_TYPES.CONTRACTOR) &&
        !this.tripRequest.additionalTransportationId
      )
        return { display: 'Waiting on Quote', color: 'amber darken-1' };
      else return { display: 'Changes Requested', color: 'amber darken-1' };
      // blue darken-2
    },
    leaveDate() {
      const d = new Date(this.tripRequest.leaveDate);
      const dt = new Date(d.valueOf() + d.getTimezoneOffset() * 60 * 1000);
      return format(dt, 'EEE MMM dd yy');
    },
    leaveTime() {
      const s = this.tripRequest.leaveTime.split(':');
      let hour = String(s[0] > 12 ? Number(s[0]) - 12 : s[0]);
      const minute = s[1];
      const ampm = s[0] >= 12 ? 'PM' : 'AM';
      if (hour == '0') hour = '12';
      return `${hour}:${minute} ${ampm}`;
    },
    returnDate() {
      const d = new Date(this.tripRequest.returnDate);
      const dt = new Date(d.valueOf() + d.getTimezoneOffset() * 60 * 1000);
      return format(dt, 'EEE MMM dd yy');
    },
    returnTime() {
      const s = this.tripRequest.returnTime.split(':');
      let hour = String(s[0] > 12 ? Number(s[0]) - 12 : s[0]);
      const minute = s[1];
      const ampm = s[0] >= 12 ? 'PM' : 'AM';
      if (hour == '0') hour = '12';
      return `${hour}:${minute} ${ampm}`;
    },
    locationName() {
      return this.tripRequest.locationId
        ? `${this.locationsById[this.tripRequest.locationId]?.name} (${
            this.locationsById[this.tripRequest.locationId]?.code
          })`
        : '-';
    },
    destinationName() {
      if (this.allDestinations && this.allDestinations.length) {
        const found = this.allDestinations.find((e) => e.id == this.tripRequest.destinationId);
        return found ? found.name : '-';
      } else return '';
    },
    route() {
      return this.tripRequest.stops.map((e) => {
        let icon = 'mdi-map-marker';
        if (e.isLocation) icon = 'mdi-school';
        else if (e.isDestination) icon = 'mdi-map-marker-check';
        return { name: e.name, icon };
      });
    },
    tripType() {
      const tt = this.tripTypesById[this.tripRequest.tripTypeId];
      return tt ? tt.name : '';
    },
    tripEvents() {
      return this.tripRequest.tripEventIds.map((e) => this.tripEventsById[e]?.name).join(', ');
    },
    vehicleOwner() {
      const owner = getVehicleOwner(this.tripRequest);
      if (!owner) return { display: '-' };
      return owner.displayName ? { display: owner.displayName, email: owner.email } : { display: owner.email };
    },
    fundingSources() {
      return this.tripRequest.fundingSources.length
        ? this.tripRequest.fundingSources.map((e) => e.name).join(', ')
        : '-';
    },
    showApproverInfo() {
      const fundingApprover = this.tripRequest.fundingSources.find((source) => {
        return this.fundingSourcesById[source.fundingSourceId];
      });

      return fundingApprover ? this.fundingSourcesById[fundingApprover.fundingSourceId].approverUsername : false;
    },
    specEq() {
      const r = [];
      if (this.tripRequest.numWheelchairSlot > 0) r.push('Wheelchair Slots');
      if (this.tripRequest.numSafetyVest > 0) r.push('Safety Vests');
      if (this.tripRequest.numFoldDownSeat > 0) r.push('Fold Down Seats');
      return r.length ? r.join(', ') : '-';
    },
    specIns() {
      if (!this.tripRequest.specialIndicators) return '-';
      const r = [];
      this.tripRequest.specialIndicators.forEach((e) => {
        const si = this.specialIndicators.find((s) => s.id == e);
        r.push(si ? si.name : '');
      });
      return r.join(', ');
    },
    vehicles() {
      if (this.vehicleTypesById && this.additionalTransportationsById) {
        const addT = {
          3: 'Approved Charter',
          2: 'Contractor',
          1: 'Rental / Dealership',
        };
        if (this.tripRequest.vehicleType === this.VEHICLE_TYPES.NORMAL && this.tripRequest.vehicleTypeId) {
          return `${this.tripRequest.numVehicles} (${
            this.vehicleTypesById[this.tripRequest.vehicleTypeId]?.name ?? 'TBD'
          })`;
        } else if (this.tripRequest.vehicleType !== this.VEHICLE_TYPES.NORMAL) {
          return `${this.tripRequest.numVehicles} (${addT[this.tripRequest.vehicleType]} - ${
            this.tripRequest.additionalTransportationId &&
            this.additionalTransportationsById[this.tripRequest.additionalTransportationId]
              ? this.additionalTransportationsById[this.tripRequest.additionalTransportationId].name
              : 'None Selected'
          })`;
        } else return 'No vehicles requested';
      } else return '-';
    },
    zone() {
      if (this.transportationConfig && this.transportationConfig.other) {
        if (this.transportationConfig.other.determineZoneBy == 'request')
          return this.locationsById[this.tripRequest.locationId].zone;
        if (this.transportationConfig.other.determineZoneBy == 'vehicle') {
          const reserveFromLocation = this.locationsById[this.tripRequest.locationId].vehicleOrder[0] || 0;
          return this.locationsById[reserveFromLocation].zone;
        }
      }
      return '-';
    },
    wasRescheduled() {
      const i = this.tripRequest.auditHistory.findIndex((e) => e.description?.includes('Rescheduled'));
      if (i >= 0)
        return {
          show: true,
          message: this.tripRequest.auditHistory[i].description,
        };
      else return { show: false };
    },
    canCreateDuplicate() {
      if (
        (this.tripRequestConfig && this.tripRequestConfig.display.duplicate) ||
        this.me.is.superAdmin ||
        this.me.is.transportationAdmin
      )
        return true;
      else return false;
    },
    tripLink() {
      let link = `/trip-request/${this.tripRequest.id}`;
      if (this.returnToDashboard) link += '?dashboard=true';
      if (this.tab) link += (this.returnToDashboard ? '&' : '?') + `${this.tab}=true`;
      return link;
    },
    showDownloadPermissionSlip() {
      if (
        (this.hasPermissionSlipEnglishTemplate || this.hasPermissionSlipSpanishTemplate) &&
        this.tripRequestConfig.templates.showDownloadPermissionSlip &&
        (this.tripRequest.approved || this.tripRequest.approval.approved)
      )
        return true;
      return false;
    },
    showCancelButton() {
      return this.tripRequest.status !== -3 && (this.me.is.superAdmin || this.me.is.transportationAdmin);
    },
    showReinstanteButton() {
      return this.tripRequest.status === -3 && (this.me.is.superAdmin || this.me.is.transportationAdmin);
    },
  },
  methods: {
    ...mapActions('tripRequest', [DUPLICATE_TRIP_REQUEST, DOWNLOAD_PERMISSION_SLIP, REINSTATE_TRIP]),
    ...mapActions('user', [SAVE_PREFERENCE]),
    async openTripRequest() {
      if (this.currentSemester.id != this.tripRequest.semesterId) {
        const { done } = await this.savePreference({
          ...this.me.data,
          semesterId: this.tripRequest.semesterId,
        });
        if (done) {
          await this.$router.push(this.tripLink);
          this.$router.go();
        }
      } else this.$router.push(this.tripLink);
    },
    getDisplayDate(date) {
      const d = new Date(date);
      const dt = new Date(d.valueOf() + d.getTimezoneOffset() * 60 * 1000);
      return format(dt, 'MMM d, yyyy');
    },
    handleExpand() {
      this.expand = !this.expand;
    },
    showHistory() {
      this.$emit('showHistory', this.tripRequest.id);
    },
    async cancel() {
      this.$emit('cancel', this.tripRequest.id);
    },
    async reschedule() {
      this.$emit('reschedule', this.tripRequest.id);
    },
    async reinstate() {
      try {
        await this.reinstateTrip(this.tripRequest.id);
        this.$myalert.success('Trip Reinstated');
        this.$router.push(`/trip-request/${this.tripRequest.id}`);
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
    async createDuplicate() {
      try {
        const r = await this.duplicateTripRequest(this.tripRequest.id);
        if (r && r.id) this.$router.push(`/trip-request/${r.id}`);
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
    showChangeSubmitter() {
      this.$emit('showChangeSubmitter', this.tripRequest.id);
    },
    async permissionSlip() {
      let inEnglish = this.hasPermissionSlipEnglishTemplate;
      if (this.hasPermissionSlipEnglishTemplate && this.hasPermissionSlipSpanishTemplate)
        inEnglish = await this.$myconfirm({
          message: 'Which language would you like to download the Permission Slip in?',
          yes: 'English',
          no: 'Spanish',
        });
      try {
        await this.downloadPermissionSlip({
          tripRequestId: this.tripRequest.id,
          uuid: randomString(16),
          reportId: 0,
          language: inEnglish ? 'english' : 'spanish',
        });
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
    getVehicleInfo(assignment) {
      if (assignment.vehicleId)
        return {
          display: `${this.vehiclesById[assignment.vehicleId]?.name}` ?? 'Unknown Vehicle',
          tooltip:
            this.vehiclesById[assignment.vehicleId]?.depotId && this.vehiclesById[assignment.vehicleId].depotId.schoolId
              ? `(${
                  this.locationsById[this.stopsById[this.vehiclesById[assignment.vehicleId].depotId].schoolId].code
                }) ${this.locationsById[this.stopsById[this.vehiclesById[assignment.vehicleId].depotId].schoolId].name}`
              : null,
        };
      else if (assignment.vehicle) return { display: assignment.vehicle };
      else return {};
    },
    getDriverInfo(assignment) {
      if (assignment.driverId && this.driversById[assignment.driverId])
        return `${this.driversById[assignment.driverId].firstName} ${this.driversById[assignment.driverId].lastName}`;
      else if (assignment.driver) return assignment.driver;
      else return '';
    },
  },
  watch: {
    allExpanded(value) {
      this.expand = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.full-width {
  width: 100%;
}
.clickable:hover {
  cursor: pointer;
}
.no-m-l {
  margin-left: 0px !important;
}
.p-r-6 {
  padding-right: 3px;
}
.green-border {
  border: 2px solid #43a047 !important;
}
.orange-border {
  border: 2px solid #ef6c00 !important;
}
.blue-border {
  border: 2px solid #1976d2 !important;
}
.amber-border {
  border: 2px solid #ffb300 !important;
}
.red-border {
  border: 2px solid #ff5252 !important;
}
.green-border-bottom {
  border-bottom: 2px solid #43a047 !important;
}
.orange-border-bottom {
  border-bottom: 2px solid #ef6c00 !important;
}
.blue-border-bottom {
  border-bottom: 2px solid #1976d2 !important;
}
.amber-border-bottom {
  border-bottom: 2px solid #ffb300 !important;
}
.red-border-bottom {
  border-bottom: 2px solid #ff5252 !important;
}
.icon-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 96px;
}
.row--dense > .col,
.row--dense > [class*='col-'] {
  padding: 2px !important;
}
.no-space {
  padding: 5px 2px 5px 8px !important;
  margin: 0px !important;
}
</style>
